.progress-container {
    display: flex;
    justify-content: space-between; 
    align-items: center; 
    position: relative; 
    margin: 20px 0; 
    padding: 10px 0; 
}

.progress-step {
    text-align: center; 
    flex: 1; 
    position: relative; 
}

.progress-circle {
    display: flex; 
    justify-content: center; 
    position: relative; 
}

.step-circle {
    width: 12px; 
    height: 12px; 
    border-radius: 50%;
    background-color: #e0e0e0; /* Gray by default */
    z-index: 1; 
}

.completed .step-circle {
    background-color: #4caf50; /* Green if completed */
}

.step-label {
    margin-bottom: 10px; 
    display: flex;
    flex-direction: column; 
    align-items: center; 
}

.step-label h6 {
    margin: 0; 
    font-size: 0.9rem; 
    color: #333; 
    text-align: center; 
    word-wrap: break-word; 
    overflow-wrap: break-word; 
    width: 200%; 
}

.step-date {
    font-size: 0.9rem; 
    color: #666; 
    margin-top: 4px; 
}

.progress-step::after {
    content: '';
    position: absolute; 
    top: 47%; 
    right: calc(50% - 2px); 
    height: 4px; 
    background-color: #e0e0e0; 
    width: 100%; 
    z-index: 0; 
}

.progress-step.completed:not(:first-child)::after {
    background-color: #4caf50; 

}


.progress-step:first-child::after {
    display: none; 
}


