.notification-wrapper {
    position: fixed;
    top: 10px;
    right: 10px;
    width: 300px;
    z-index: 6000;
}

.notification-item {
    animation: SlideLeft 0.4s;
    animation-fill-mode: forwards;
    width: 300px;
    font-size: 14px;
}

@keyframes SlideLeft {
    0% {
        margin-left: 120%;
    }

    100% {
        margin-left: 0;
    }
}

@keyframes SlideRight {
    0% {
        margin-left: 0;
    }

    100% {
        margin-left: 120%;
    }
}

.notification-item.exit {
    animation: SlideRight 0.4s;
    animation-fill-mode: forwards;
}

.notification-item .bar {
    height: 1px;
    background-color: #fff;
}