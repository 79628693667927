/* Custom styles for the SettingsCard component */
.card-header button {
    text-decoration: none;
    color: #007bff;
}

.card-header button:hover {
    color: #0056b3;
}

.card-body form {
    max-width: 400px;
    margin: auto;
}

.table-wrapper {
    overflow: auto;
    width: 100vh-40vh;
    /* height: 100vw-60vw; */
    margin: 5px 10px;
}

/* .nav-item.active .nav-link,
.nav-link.active { */
/* background-color: #5ea5f1; */
/* color: white !important;
    font-weight: bold !important;
} */