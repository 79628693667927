@media only screen and (min-width: 1024px) {
    .search-control {
        height: 41px !important;
    }

    .search-btn {
        height: 41px !important;
    }

    .cat-box {
        height: 50px !important;
    }

    .img-xs {
        width: 50px !important;
        height: 50px !important;
    }

    .img-sm {
        width: 65px !important;
        height: 65px !important;
    }

    .img-md {
        width: 112px !important;
        height: 112px !important;
    }

    .img-nor {
        width: 150px !important;
        height: 150px !important;
    }

    .img-lg {
        width: 400px !important;
        height: 400px !important;
    }

    .mobile {
        margin-top: -60px !important;
    }

    .count-box {
        width: 40px !important;
        height: 30px !important;
    }

    .hover-scale {
        transition: 0.1s all linear !important;
    }

    .hover-scale:hover {
        transform: scale(1.05) !important;
        transition: 0.1s all linear !important;
    }

    .prod-img-box {
        display: block !important;
        width: 320px !important;
        height: 420px !important;
    }

    .valid-thru {
        width: 80px !important;
    }

    .valid-thru:focus {
        transition: all .1s linear !important;
        border-color: #1266f1 !important;
        box-shadow: inset 0px 0px 0px 1px #1266f1 !important;
    }

    .prof-side-active {
        transition: all 0.3s linear !important;
        background-color: #1266f1 !important;
    }

    .prof-side-active i {
        color: #ffffff !important;
    }

    .prof-side-active span {
        color: #ffffff !important;
    }

    .profile-body {
        height: calc(100vh - 58px) !important;
    }

    .vh-100-top {
        height: calc(100vh - 90px);
    }

    .vh-100-top-admin {
        height: calc(100vh - 52px);
    }

    .login-group {
        position: relative !important;
        height: 60px !important;
        width: 100% !important;
        overflow: hidden !important;
    }

    .login-control {
        border: none !important;
        outline: none !important;
        width: 100% !important;
        height: 100% !important;
        padding-top: 20px !important;
    }

    .login-label {
        width: 100% !important;
        height: 100% !important;
        position: absolute !important;
        bottom: 0% !important;
        left: 0% !important;
        pointer-events: none !important;
        border-bottom: 1px solid gray !important;
    }

    .login-label::after {
        content: '';
        width: 100% !important;
        height: 100% !important;
        position: absolute !important;
        bottom: -1px !important;
        left: 0% !important;
        border-bottom: 2px solid #1266f1 !important;
        transform: translateX(-101%) !important;
        transition: all 0.2s linear !important;
    }

    .login-control:focus+.login-label::after,
    .login-control:valid+.login-label::after {
        transform: translateX(0%) !important;
        transition: all 0.2s linear !important;
    }

    .login-label-name {
        position: absolute !important;
        bottom: 5px !important;
        left: 0% !important;
        transition: all 0.2s linear !important;
        font-size: 15px !important;
    }

    .login-control:focus+.login-label .login-label-name,
    .login-control:valid+.login-label .login-label-name {
        transform: translateY(-150%) !important;
        transition: all 0.2s linear !important;
        font-size: 12px !important;
    }

    .login-label-danger {
        border-bottom: 1px solid #f93154 !important;
    }

    .login-label-danger::after {
        border-bottom: 2px solid #f93154 !important;
    }
}