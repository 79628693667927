.side-nav-bar-wrapper {
    position: fixed;
    top: 0%;
    left: 120%;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, .5);
    z-index: 1040;
}

.side-nav-bar-wrapper.open {
    animation: side-nav-bar-wrapper-open .3s;
    animation-fill-mode: forwards;
    left: 0%;
}

.side-nav-bar-wrapper.close {
    animation: side-nav-bar-wrapper-close .4s;
    animation-fill-mode: forwards;
}

.side-nav-bar {
    width: 250px;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    position: absolute;
    top: 0%;
    right: -120%;
    z-index: 1050;
}

.side-nav-bar.open {
    animation: side-nav-bar-open .3s;
    animation-fill-mode: forwards;
    right: 0%;
}

.side-nav-bar.close {
    animation: side-nav-bar-close .4s;
    animation-fill-mode: forwards;
    right: -120%;
}

.side-nav-bar .active {
    background-color: #dc3545;
    color: #fff !important;
}

@keyframes side-nav-bar-open {
    0% {
        right: -120%;
    }

    100% {
        right: 0%;
    }
}

@keyframes side-nav-bar-close {
    0% {
        right: 0%;
    }

    100% {
        right: -120%;
    }
}

@keyframes side-nav-bar-wrapper-open {
    0% {
        background-color: rgba(0, 0, 0, 0);
    }

    100% {
        background-color: rgba(0, 0, 0, .5);
    }
}

@keyframes side-nav-bar-wrapper-close {
    0% {
        background-color: rgba(0, 0, 0, .5);
        left: 0%;
    }

    99.99% {
        background-color: rgba(0, 0, 0, 0);
        left: 0%;
    }

    100% {
        background-color: rgba(0, 0, 0, 0);
        left: 120%;
    }
}