.main-sidebar {
    background-color: #1a7bea;
    /* Adjust the color as needed */
}

.nav-link {
    font-size: 16px;
    /* Adjust the font size as needed */
    /* font-weight: bold; Add weight for a bolder look */
}

/* .nav-link {
    padding: 10px 15px; 
  } */

.nav-link.active {
    /* background-color: #7eb2f743; */
    /* Adjust the color as needed */
    border-radius: 8px;
    font-weight: bold;

}

.minimized {
    width: 80px;
    /* Adjust width for icons */
}

.minimized-text {
    display: none;
    /* Optional: hide text on minimization */
}