/* flyout */
.flyout-container {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 400px;
    /* You can adjust the width as needed */
    background-color: white;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
    z-index: 2000;
    transition: transform 0.3s ease;
}

.flyout-container.hidden {
    transform: translateX(100%);
}

.flyout-container.visible {
    transform: translateX(0);
}

.flyout-content {
    padding: 20px;
}

.close-btn {
    background-color: #B1740F;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
}