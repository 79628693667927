@import "./variables.css";
/* @import '../components/custom/assets/scss/Creative.scss'; */

header {
    height: var(--header-height);
}

.fixed-top-bar {
    padding-top: var(--header-height);
}

.outline-none {
    outline: none !important;
}

.fit-cover {
    object-fit: cover !important;
    object-position: center !important;
}

a {
    text-decoration: none !important;
}

.fit-contain {
    object-fit: contain !important;
    object-position: center !important;
}

/* Cursor */

.cursor-pointer {
    cursor: pointer !important;
}

.cursor-no-drop {
    cursor: no-drop !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
    display: none !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
    -ms-overflow-style: none !important;
    /* IE and Edge */
    scrollbar-width: none !important;
    /* Firefox */
}

/* BOX */

.box-sm {
    display: block !important;
    width: 55px !important;
    height: 55px !important;
}

/* Hover */

.hover-border {
    transition: all 0.1s linear !important;
}

.hover-border:hover {
    border: 1px solid #1266f1 !important;
    transition: all 0.1s linear !important;
}

.bg-hover {
    transition: all 0.1s ease-in-out !important;
}

.bg-hover:hover {
    background-color: #e0e0e0 !important;
    transition: all 0.1s ease-in-out !important;
}

.scale {
    transition: all 0.3s ease !important;
}

.scale:hover img {
    transform: scale(1.1) !important;
    transition: all 0.3s ease !important;
}

.hover-underline:hover {
    text-decoration: underline;
}

.hover-primary {
    transition: all 0.1s ease-in-out !important;
}

.hover-primary:hover {
    color: #1266f1 !important;
}

/* Wrap */

.text-wrap {
    white-space: wrap !important;
}

/* Animation */

.hover-to-show+div {
    display: flex !important;
    position: absolute !important;
    top: 100% !important;
    left: 0% !important;
    justify-content: center !important;
    align-items: center !important;
}

.hover-to-show:hover+div,
.hover-to-show+div:hover {
    animation: edit-slide-up 0.2s ease-in-out 1 !important;
    top: 0% !important;
}

.edit-name-modal-content {
    animation: edit-modal-pop-up 0.1s ease-in-out 1;
}

.blink {
    animation: blink-dot 2s ease-in-out infinite;
}

.slide-up-network-indicator {
    animation: slide-up-network-indicator 0.5s ease-in-out 1;
}

.chevron-rotate-down {
    transform: rotateZ(0deg);
    transition: all 0.2s linear;
}

.chevron-rotate-up {
    transform: rotateZ(-180deg);
    transition: all 0.2s linear;
}

.resize-none {
    resize: none;
}

.pop-up {
    animation: pop-up 0.2s ease-in-out 1;
}

.pop-down {
    animation: pop-down 0.2s ease-in-out 1;
}

.control-arrow {
    width: 15%;
}

.remove-margin-bottom p {
    margin-bottom: 0px;
}

.text-yellow {
    color: #ffc107;
}

.add-to-cart:hover {
    background-color: #1266f1;
    color: white;
}

@keyframes slide-up-network-indicator {
    0% {
        bottom: -10%;
    }

    100% {
        bottom: 0%;
    }
}

@keyframes blink-dot {
    0% {
        opacity: 0;
    }

    25% {
        opacity: 1;
    }

    75% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes edit-modal-pop-up {
    0% {
        transform: scale(0.3);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes edit-slide-up {
    0% {
        top: 100%;
    }

    100% {
        top: 0%;
    }
}

@keyframes pop-up {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes pop-down {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

.search-container {
    position: relative;
    display: inline-block;
    height: 50px;
    width: 55vw;
    vertical-align: bottom;
}

.search-button {
    position: absolute;
    font-size: 22px;
    width: 100%;
    height: 100%;
    background-color: yellow;
    border: none;
    color: rgb(0, 0, 0);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.4s, color 0.4s;
}

.search-button:hover {
    background-color: white;
    color: blue;
}

.search {
    position: absolute;
    right: 50px;
    /* Adjust according to your button width */
    background-color: white;
    border: none;
    padding: 0;
    width: 0;
    height: 100%;
    z-index: 10;
    transition: width 0.4s ease, padding 0.4s ease;
    outline: none;
}

.search:focus {
    width: 300px;
    /* Adjust the width as needed */
    padding: 0 16px;
}

.search-button .search-icon {
    font-size: 22px;
}

.search-container .search-button .search-icon {
    pointer-events: none;
}

@keyframes blink {
    50% {
        opacity: 0;
    }
}

.blinking-badge {
    animation: blink 1s infinite;
}

.button-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.btn-add-to-cart {
    background-color: #000;
    /* Black for Add to Cart */
    color: white;
    padding: 10px 20px;
    font-size: 16px;
    /* font-weight: bold; */
    text-align: center;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    flex: 1;
    margin-right: 5px;
    /* Space between buttons */
}

.btn-buy-now {
    background-color: #ffbf00;
    /* Yellow for Buy Now */
    color: black;
    padding: 10px 20px;
    font-size: 16px;
    /* font-weight: bold; */
    text-align: center;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    flex: 1;
    margin-left: 5px;
    /* Space between buttons */
}

.btn-add-to-cart:hover,
.btn-buy-now:hover {
    opacity: 0.9;
}